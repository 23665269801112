import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Error from '../layout/Error';
import { myMSALObj, tokenRequest } from '../../store/authConfig';
import { AuthResponse } from 'msal';

const printBtn = {
    width: '140px',
    fontSize: '13px',
    padding: '5px 10px',
    borderRadius: 0,
    textTransform: 'uppercase' as 'uppercase'
};

const PrintDropDown = (props: any) => {
    const [dropdownOpen, setOpen] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [isExporting, setIsExporting] = useState(false);
    const [majorError, setMajorError] = useState(false);

    const { reportGroup } = useParams<any>();

    const toggle = () => setOpen(!dropdownOpen);

    const sendPrintRequest = useCallback(async (reportGroup: string, reportName: string) => {
        if (isExporting) return;
        // update state
        setIsExporting(true);
        let tokenResponse: AuthResponse | null = null;
        try {
            tokenResponse = await myMSALObj.acquireTokenSilent(tokenRequest);
        } catch {
            myMSALObj.acquireTokenRedirect(tokenRequest);
        }
        var isMobile = window.innerWidth <= 576;
        await fetch(`PowerBI/Export?reportName=${reportName}&reportGroup=${reportGroup}&isMobile=${isMobile}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + tokenResponse?.accessToken
                }
            })
            .then(response => {
                if (response.ok) {
                    let intervalId = setInterval(async () => {
                        await fetch(`PowerBI/Poll?reportGroup=${reportGroup}&isMobile=${isMobile}`, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + tokenResponse?.accessToken
                                }
                            })
                            .then(response => response.json() as any)
                            .then(data => {
                                setPercentage(data);
                                if (data === 100) {
                                    window.location.href = (`/PowerBI/Download?reportGroup=${reportGroup}&isMobile=${isMobile}`);
                                    clearInterval(intervalId);
                                    setIsExporting(false);
                                    setPercentage(0);
                                }
                            }).catch(error => {
                                setMajorError(true);
                                setIsExporting(false);
                            });
                    }, 10000);
                }
                else {
                    setMajorError(true);
                    setIsExporting(false);
                }
            }).catch(error => {
                setMajorError(true);
                setIsExporting(false);
            });
    }, [isExporting]);

    const sendExcelRequest = useCallback(async (reportGroup: string) => {
        if (isExporting) return;
        // update state
        setIsExporting(true);
        let tokenResponse: AuthResponse | null = null;
        try {
            tokenResponse = await myMSALObj.acquireTokenSilent(tokenRequest);
        } catch {
            myMSALObj.acquireTokenRedirect(tokenRequest);
        }
        var isMobile = window.innerWidth <= 576;
        await fetch(`PowerBI/ExportExcelReport?reportGroup=${reportGroup}&isMobile=${isMobile}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + tokenResponse?.accessToken
                }
            })
            .then(response => {
                if (response.ok) {
                    let intervalId = setInterval(async () => {
                        await fetch(`PowerBI/PollExcelReport?reportGroup=${reportGroup}&isMobile=${isMobile}`, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + tokenResponse?.accessToken
                                }
                            })
                            .then(response => response.json() as any)
                            .then(data => {
                                if (data === 101) {
                                    setPercentage(0);
                                    setMajorError(true);
                                    setIsExporting(false);
                                    clearInterval(intervalId);
                                }
                                else if (data === 100) {
                                    window.location.href = (`/PowerBI/DownloadExcelReport?reportGroup=${reportGroup}&isMobile=${isMobile}`);
                                    clearInterval(intervalId);
                                    setIsExporting(false);
                                    setPercentage(0);
                                }
                                else {
                                    setPercentage(data);
                                }
                            }).catch(error => {
                                setMajorError(true);
                                setIsExporting(false);
                            });
                    }, 10000);
                }
                else {
                    setMajorError(true);
                    setIsExporting(false);
                }
            }).catch(error => {
                setMajorError(true);
                setIsExporting(false);
            });
    }, [isExporting]);

    const currentReportGroup = reportGroup || props.printMenuItems?.[0]?.reportGroup;
    const menuItems = props.printMenuItems?.filter((p: any) => p.reportGroup === currentReportGroup) || [];

    return (
        <>
            <Error show={majorError} errorMsg={undefined} />
            {
                menuItems.length > 0 && window.innerWidth > 576 &&
                <li>
                    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} disabled={props.disableDropdown} direction="down">
                        <DropdownToggle caret={!isExporting} disabled={isExporting} style={printBtn}>
                            {isExporting ?
                                <>Exporting {percentage}%</> :
                                <> <span className={props.disableDropdown ? "text-muted" : ""}>Print/Export</span>  </>
                            }
                        </DropdownToggle>
                        <DropdownMenu style={{ maxWidth: '100px !important' }}>
                                {menuItems.map((p: any) => {
                                    if (p.isExcelReport && p.displayName != 'Export Excel') // temporarily hiding export option
                                        return <DropdownItem key={p.reportName} onClick={() => sendExcelRequest(currentReportGroup)}>{p.displayName}</DropdownItem>;
                                    else if (!p.isExcelReport && p.displayName != 'Export Excel') // temporarily hiding export option
                                        return <DropdownItem key={p.reportName} onClick={() => sendPrintRequest(currentReportGroup, p.reportName)}>{p.displayName}</DropdownItem>;
                                })}
                        </DropdownMenu>
                    </ButtonDropdown>
                </li>
            }
        </>
    );
}

export default PrintDropDown;
